var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 bordered-item",attrs:{"headers":_vm.headers,"items":_vm.internalItems,"items-per-page":10,"dense":_vm.internalItems.length > 3,"page":_vm.page,"hideDefaultFooter":"","no-data-text":"Add your invoice from the list above"},on:{"update:page":function($event){_vm.page=$event},"click:row":_vm.openInvoice,"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.account",fn:function(ref){
var item = ref.item;
return [_c('account-ref',{staticClass:"clickable",attrs:{"icon-props":{small: true},"id":item.account.id},nativeOn:{"click":function($event){$event.stopPropagation();_vm.$router.push('/accounts/' + item.account.id).catch(function () {})}}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('calendar-date',{attrs:{"date":item.date}})]}},{key:"item.amountDisputed",fn:function(ref){
var i = ref.item;
return [_c('amount-field',{attrs:{"currency":i.currency,"amount":i.amountDisputed,"errorMessages":i.errorMessage,"rules":_vm.valueRangeDispute(i),"singleLine":true,"dense":true,"disabled":_vm.disabled},on:{"update:amount":function($event){return _vm.$set(i, "amountDisputed", $event)},"update:errorMessages":function($event){return _vm.$set(i, "errorMessage", $event)},"update:error-messages":function($event){return _vm.$set(i, "errorMessage", $event)},"input":_vm.updateInvoices},nativeOn:{"mousedown":function($event){_vm.preventOpenInvoice = true},"click":function($event){$event.stopPropagation();}}})]}},{key:"item.amountPromised",fn:function(ref){
var i = ref.item;
return [_c('amount-field',{attrs:{"currency":i.currency,"amount":i.amountPromised,"errorMessages":i.errorMessage,"rules":_vm.valueRangePromise(i),"singleLine":true,"dense":true,"disabled":_vm.disabled},on:{"update:amount":function($event){return _vm.$set(i, "amountPromised", $event)},"update:errorMessages":function($event){return _vm.$set(i, "errorMessage", $event)},"update:error-messages":function($event){return _vm.$set(i, "errorMessage", $event)},"input":_vm.updateInvoices},nativeOn:{"mousedown":function($event){_vm.preventOpenInvoice = true},"click":function($event){$event.stopPropagation();}}})]}},{key:"item.amount",fn:function(ref){
var i = ref.item;
return [_vm._v(_vm._s(_vm.formatCurrency(i.amount, i.currency)))]}},{key:"item.balance",fn:function(ref){
var i = ref.item;
return [_vm._v(_vm._s(_vm.formatCurrency(i.balance, i.currency)))]}},(!_vm.disabled && !_vm.editAmountOnly)?{key:"item.remove",fn:function(ref){
var i = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeInvoice(i)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icon('i.Close')))])],1)]}}:null],null,true)}),(_vm.pageCount > 1)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }