<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="internalItems"
      :items-per-page="10"
      :dense="internalItems.length > 3"
      :page.sync="page"
      hideDefaultFooter
      no-data-text="Add your invoice from the list above"
      class="elevation-0 bordered-item"
      @click:row="openInvoice"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.account`]="{ item }">
        <account-ref
          :icon-props="{small: true}"
          :id="item.account.id"
          class="clickable"
          @click.native.stop="$router.push('/accounts/' + item.account.id).catch(() => {})"
        />
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <calendar-date :date="item.date" />
      </template>

      <template v-slot:[`item.amountDisputed`]="{ item: i }">
        <amount-field
          :currency="i.currency"
          :amount.sync="i.amountDisputed"
          :errorMessages.sync="i.errorMessage"
          :rules="valueRangeDispute(i)"
          :singleLine="true"
          :dense="true"
          :disabled="disabled"
          @input="updateInvoices"
          @mousedown.native="preventOpenInvoice = true"
          @click.native.stop=""
        >
        </amount-field>
      </template>
      <template v-slot:[`item.amountPromised`]="{ item: i }">
        <amount-field
          :currency="i.currency"
          :amount.sync="i.amountPromised"
          :errorMessages.sync="i.errorMessage"
          :rules="valueRangePromise(i)"
          :singleLine="true"
          :dense="true"
          :disabled="disabled"
          @input="updateInvoices"
          @mousedown.native="preventOpenInvoice = true"
          @click.native.stop=""
        >
        </amount-field>
      </template>
      <template v-slot:[`item.amount`]="{ item: i }">{{formatCurrency(i.amount, i.currency)}}</template>
      <template v-slot:[`item.balance`]="{ item: i }">{{formatCurrency(i.balance, i.currency)}}</template>
      <template
        v-if="!disabled && !editAmountOnly"
        v-slot:[`item.remove`]="{ item: i }"
      >
        <v-btn
          icon
          color="red"
          @click.stop="removeInvoice(i)"
        >
          <v-icon>{{$icon('i.Close')}}</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div
      class="text-center pt-2"
      v-if="pageCount > 1"
    >
      <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import navigation from '@/navigationController'

export default {
  components: {
    AccountRef: () => import('@/components/documents-ref/account-ref'),
    CalendarDate: () => import('@/components/calendar-date'),
    AmountField: () => import('@/components/amount-field')
  },
  data () {
    return {
      preventOpenInvoice: false,
      errorMessages: '',
      page: 1,
      pageCount: 0
    }
  },
  computed: {
    internalItems () {
      return this.lodash.cloneDeep(this.items)
    }
  },
  props: {
    headers: Array,
    items: Array,
    disabled: Boolean,
    editAmountOnly: Boolean
  },
  methods: {
    valueRangeDispute (i) {
      const isPositiveInvoice = i.disputeAmountAllowed >= 0
      const signedErrorMessage = this.$t('t.InvalidValueRange', { min: Math.min(0, i.disputeAmountAllowed), max: Math.max(0, i.disputeAmountAllowed) })
      const result = this.disabled ||
        (
          isPositiveInvoice ? i.amountDisputed >= 0 && i.amountDisputed <= i.disputeAmountAllowed : i.amountDisputed < 0 && i.amountDisputed >= i.disputeAmountAllowed
        )
      const errorMessage = result ? null : signedErrorMessage
      if (errorMessage || i.errorMessage) {
        this.$set(i, 'errorMessage', errorMessage)
      }
    },
    valueRangePromise (i) {
      const isPositiveInvoice = i.promiseAmountAllowed > 0
      const signedErrorMessage = this.$t('t.InvalidValueRange', { min: Math.min(0, i.promiseAmountAllowed), max: Math.max(0, i.promiseAmountAllowed) })

      const result = this.disabled || (isPositiveInvoice ? i.amountPromised <= i.promiseAmountAllowed && i.amountPromised > 0 : i.amountPromised >= i.promiseAmountAllowed && i.amountPromised < 0)
      const errorMessage = result ? null : signedErrorMessage
      if (errorMessage || i.errorMessage) {
        this.$set(i, 'errorMessage', errorMessage)
      }
    },
    removeInvoice (i) {
      const indexInvoice = this.lodash.findIndex(this.internalItems, item => item.id === i.id)
      this.internalItems.splice(indexInvoice, 1)
      this.updateInvoices()
    },
    updateInvoices () {
      this.$emit('update:items', this.internalItems)
    },
    openInvoice (i) {
      if (this.preventOpenInvoice) {
        this.preventOpenInvoice = false
        return
      }

      // Prevent opening the invoice if there is some text selected. This allow copying invoice number or amount from the list without having to go to the document
      if (!document.getSelection().toString().length) {
        navigation.navigateTo(i.id, 'invoices')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> tr
  cursor pointer
</style>
